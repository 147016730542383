import { ToastContentProps } from 'react-toastify/dist/types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useGetCurrentUser from '../../../features/users/services/useGetCurrentUser';
import useSetCurrentUserData from '../../../features/users/services/useSetCurrentUserData';
import { APButton, APButtonLoading } from '@ap/design-system';

function AlertToast(props: Partial<ToastContentProps<unknown>>) {
  const { closeToast, toastProps } = props;
  const { setData, loading } = useSetCurrentUserData();
  const currentUser = useGetCurrentUser();

  const handleClick = () => {
    if (toastProps?.toastId) {
      (async () => {
        await setData([
          {
            name: 'alertShown',
            value: [...currentUser.alertShown, toastProps.toastId],
          },
        ]);
      })();
    }

    if (closeToast) {
      closeToast();
    }
  };

  return (
    <Stack direction='column' spacing={2} pl={2}>
      <Typography sx={{ fontStyle: 'italic', lineHeight: 1.1 }}>Never show this message again?</Typography>
      <Stack direction='row' alignItems='center' spacing={2}>
        <APButton size='small' onClick={closeToast}>
          Close
        </APButton>
        <APButtonLoading size='small' variant='contained' onClick={handleClick} loading={loading}>
          Yes
        </APButtonLoading>
      </Stack>
    </Stack>
  );
}

export default AlertToast;
