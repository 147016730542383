import { RouteObject } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import devicesRoutes from '../../features/devices/router/routes';
import errorRoutes from '../../features/errors/router/routes';
import eventsRoutes from '../../features/masterEvents/router/routes';
import useCurrentUserCan from '../../features/users/services/useCurrentUserCan';
import usersRoutes from '../../features/users/router/routes';
import reportingRoutes from '../../features/reporting/router/routes';
import blogRoutes from '../../features/blog/router/routes';
import useBreakpointDown from '../../template/hooks/useBreakpointDown';

const useSentryRoutes = wrapUseRoutes(useRoutes);

function AppRouter() {
  const userCan = useCurrentUserCan();
  const isMobile = useBreakpointDown('md');

  const routes: RouteObject[] = [
    ...eventsRoutes(userCan, isMobile),
    ...devicesRoutes(userCan),
    ...reportingRoutes(userCan),
    ...usersRoutes(userCan),
    ...blogRoutes(userCan),
    ...errorRoutes,
  ];

  return useSentryRoutes(routes);
}

export default AppRouter;
