/**
 * Safely parse a JSON string into a JavaScript object.
 *
 * @param {string} text The string to parse
 */
export function parseJsonObject(text: string): any {
  let obj = {};

  try {
    // Parse the JSON string
    obj = JSON.parse(text);
  } catch (e) {
    // Nothing to do...
  }

  return obj;
}
