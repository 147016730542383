import { createHttpLink } from '@apollo/client';

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_API_URL}/graphql`,
  fetch: (uri, options) => {
    return fetch(uri, options);
  },
});

export default httpLink;
