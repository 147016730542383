import { useEffect } from 'react';

const printConsole = () => {
  const version = import.meta.env.VITE_VERSION ? import.meta.env.VITE_VERSION.toString() : 'Unknown version';

  const message1 = `
       d8888 8888888b.        .d8888b.  8888888b.  888b     d888      8888888b.        d8888 8888888b.  
      d88888 888   Y88b      d88P  Y88b 888   Y88b 8888b   d8888      888  "Y88b      d88888 888   Y88b 
     d88P888 888    888      888    888 888    888 88888b.d88888      888    888     d88P888 888    888 
    d88P 888 888   d88P      888        888   d88P 888Y88888P888      888    888    d88P 888 888   d88P 
   d88P  888 8888888P"       888        8888888P"  888 Y888P 888      888    888   d88P  888 8888888P"  
  d88P   888 888             888    888 888 T88b   888  Y8P  888      888    888  d88P   888 888        
 d8888888888 888             Y88b  d88P 888  T88b  888   "   888      888  .d88P d8888888888 888        
d88P     888 888              "Y8888P"  888   T88b 888       888      8888888P" d88P     888 888\n`;
  const message2 = `Version ${version}`;
  const message3 = 'Made with love in 🇨🇭 Le Brassus & 🇺🇸 NY by the ITCS team (Digital Factory)\n';

  console.log(`%c ${message1}`, 'font-size: 7px; font-weight: bold;');
  console.log(`%c${message2}`, 'font-weight: bold;');
  console.log(`%c${message3}`, 'font-weight: normal;');
};

function AppConsole() {
  useEffect(() => {
    console.clear();
    printConsole();
  }, []);

  return null;
}

export default AppConsole;
