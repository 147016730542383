import { lazy } from 'react';

const NotFound = lazy(() => import('../pages/NotFound'));

const routes = [
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
