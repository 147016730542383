import { gql } from '@apollo/client';

export const GET_MASTER_DATA_COUNTRIES = gql`
  query masterDataCountries($query: MasterDataCountryQueryInput!, $limit: Int = 0) {
    masterDataCountries(query: $query, limit: $limit) {
      _id
      translations {
        _id
        name
      }
    }
  }
`;

export const GET_MASTER_DATA_COUNTRY = gql`
  query masterDataCountry($query: MasterDataCountryQueryInput!) {
    masterDataCountry(query: $query) {
      _id
    }
  }
`;
