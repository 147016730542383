import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { APButtonLoading, APDialog, APIcon } from '@ap/design-system';
import { AppContext } from '../../../../bootstrap/App';
import BlogArticle from '../../models/BlogArticle';
import { parseJsonObject } from '../../../../helper/json';
import { parseHtml } from '../../../../helper/html';
import { URI_BLOG } from '../../router/uri';
import useSetCurrentUserData from '../../../users/services/useSetCurrentUserData';
import useGetCurrentUser from '../../../users/services/useGetCurrentUser';

function BlogInfoPopup() {
  const { realmObject } = useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();

  const currentUser = useGetCurrentUser();
  const { setData } = useSetCurrentUserData();

  const [open, setOpen] = useState(false);
  const [blogArticle, setBlogArticle] = useState<BlogArticle>();
  const [loading, setLoading] = useState(false);

  const setReadAt = async () => {
    await setData([
      {
        name: 'blogInfoPopupReadAt',
        value: new Date(),
      },
    ]);
  };

  const handleClose = async () => {
    setOpen(false);
    await setReadAt();
    setBlogArticle(undefined);
  };

  const handleReadMore = async () => {
    if (!blogArticle) {
      return;
    }

    setLoading(true);
    await setReadAt();
    setOpen(false);
    navigate(`/${URI_BLOG}/${blogArticle.slug}`);
  };

  useEffect(() => {
    (async () => {
      if (!currentUser.welcomeWizardCompleted) {
        // Do not display the pop-up if the user has not completed the welcome wizard
        return;
      }

      if (location.pathname && location.pathname.startsWith(`/${URI_BLOG}`)) {
        // Do not display the pop-up if the user is already on the blog
        return;
      }

      const response = await realmObject?.functions.hasBlogInfoPopupToRead();
      const responseJson = parseJsonObject(response);

      if (responseJson?.article) {
        setBlogArticle(responseJson.article as BlogArticle);
        setOpen(true);
      }
    })();
  }, [currentUser.welcomeWizardCompleted]);

  if (!blogArticle) {
    return null;
  }

  return (
    <APDialog
      onClose={handleClose}
      open={open}
      title={parseHtml(blogArticle.title)}
      maxWidth='sm'
      actions={
        <APButtonLoading endIcon={<APIcon name='NavigateNext' />} onClick={handleReadMore} loading={loading}>
          Read more
        </APButtonLoading>
      }
    >
      {blogArticle.coverImage && (
        <CardMedia sx={{ mb: 4 }} component='img' height='160' image={blogArticle.coverImage.urlRegular} />
      )}
      <Box>{parseHtml(blogArticle.excerpt)}</Box>
    </APDialog>
  );
}

export default BlogInfoPopup;
