import Dialog from '@mui/material/Dialog';

interface Props {
  open: boolean;
  onClose: () => void;
}

function DialogEasterEgg(props: Props) {
  const { open, onClose } = props;

  return (
    <Dialog onClose={onClose} open={open} maxWidth={false}>
      <iframe
        width='640'
        height='360'
        src='https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ?controls=0&autoplay=1&controls=0'
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </Dialog>
  );
}

export default DialogEasterEgg;
