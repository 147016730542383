import Box from '@mui/material/Box';
import { APProgressCircular } from '@ap/design-system';
import styles from './LoadingSpinner.module.scss';

interface Props {
  fullPage?: boolean;
  inline?: boolean;
  py?: number;
}

function LoadingSpinner(props: Props) {
  const { fullPage = false, inline = false, py = 0 } = props;

  const spinner = (
    <Box className={`${styles.root} ${inline ? styles.inline : ''}`} sx={{ py }}>
      <APProgressCircular />
    </Box>
  );

  if (fullPage) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%' }}>
        {spinner}
      </Box>
    );
  }

  return spinner;
}

export default LoadingSpinner;
