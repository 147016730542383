import { useEffect, useState } from 'react';
import useFetchMasterEvents from '../../../masterEvents/services/masterEvents/useFetchMasterEvents';
import GuestsFilter, { Item } from '../../../guests/components/GuestsFilter/GuestsFilter';
import useFetchCountries from '../../../countries/services/useFetchCountries';

interface Props {
  onChange: (ids: string[]) => void;
}

function EventReportsFiltersCountry(props: Props) {
  const { onChange } = props;

  const { fetchMasterEvents, masterEvents, loading: loadingMasterEvents } = useFetchMasterEvents();
  const { fetchCountries, countries, loading: loadingCountries } = useFetchCountries();

  const [filterItems, setFilterItems] = useState<Item[]>([]);

  // On component mount, load the needed data
  useEffect(() => {
    fetchMasterEvents({ limit: -1, offset: 0, sortBy: 'name', sortDirection: 'asc' });
    fetchCountries();
  }, []);

  useEffect(() => {
    if (masterEvents && masterEvents.length > 0 && countries && countries.length > 0) {
      const uniqueCountries = [...new Set(masterEvents.map((item) => item.country?._id))];

      const items = [];
      for (const uniqueCountry of uniqueCountries) {
        const country = countries.find((country) => country._id === uniqueCountry);
        if (country) {
          items.push({ _id: country._id, label: country.name });
        }
      }

      setFilterItems(items.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }, [masterEvents]);

  if (filterItems.length <= 0) {
    return null;
  }

  return <GuestsFilter id='country-filter' label='Countries' items={filterItems} onChange={onChange} />;
}

export default EventReportsFiltersCountry;
