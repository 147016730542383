import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { APFlag } from '@ap/design-system';
import { APFlagCountryCode } from '@ap/design-system/dist/components/APFlag/types';
import MasterEventModel from '../../models/MasterEvent';
import { StyledCardMedia } from './styles';
import { parseHtml } from '../../../../helper/html';
import useBreakpointDown from '../../../../template/hooks/useBreakpointDown';

function brTitle(title: string) {
  return parseHtml(title.replace('Audemars Piguet', `Audemars Piguet<br />`).replace('AP House', `AP House<br />`));
}

interface Props {
  masterEvent: MasterEventModel;
}

function MasterEventHeader(props: Props) {
  const { masterEvent } = props;
  const { name, coverImage, country } = masterEvent;

  const isMobile = useBreakpointDown('md');

  return (
    <Stack
      spacing={4}
      direction={isMobile ? 'column' : 'row'}
      justifyContent='space-between'
      alignItems={isMobile ? 'flex-start' : 'center'}
      sx={{ width: '100%' }}
    >
      <Stack
        spacing={4}
        direction={isMobile ? 'column' : 'row'}
        sx={{
          ...(isMobile && { width: '100%' }),
        }}
      >
        {coverImage && <StyledCardMedia image={coverImage.urlFull} />}
        <Typography variant='displayTitle'>{brTitle(name)}</Typography>
      </Stack>
      {country && !isMobile && (
        <APFlag
          countryCode={country._id.toLowerCase() as APFlagCountryCode}
          countryName={country.name}
          showTooltip
          height='40px'
          width='40px'
        />
      )}
    </Stack>
  );
}

export default MasterEventHeader;
