import { useContext, useState } from 'react';
import { isDate } from 'date-fns';
import { APInputDateRangePicker } from '@ap/design-system';
import { DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { AppContext } from '../../../../bootstrap/App';

interface Props {
  onChange: (dateRange: DateRange<Date> | null) => void;
}

function EventReportsFiltersStartEnd(props: Props) {
  const { onChange } = props;

  const { regionalFormat } = useContext(AppContext);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  return (
    <APInputDateRangePicker
      label=''
      hiddenLabel
      value={[startDate, endDate]}
      onChange={(dateRange) => {
        if (isDate(dateRange[0]) && isDate(dateRange[1])) {
          setStartDate(dateRange[0]);
          setEndDate(dateRange[1]);
          onChange(dateRange);
        }
      }}
      required={false}
      minDate={new Date(2000, 0)}
      maxDate={new Date()}
      dateAdapter={AdapterDateFns}
      adapterLocale={regionalFormat}
    />
  );
}

export default EventReportsFiltersStartEnd;
