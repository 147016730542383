/**
 * Returns the initials
 *
 * @param {string} input
 * @param {number} limit Limit where initials must be truncated
 * @returns {string}
 */
export const getInitials = (input: string, limit = 2): string => {
  return input
    .split(/-| /)
    .map((word) => word[0])
    .join('')
    .slice(0, limit);
};

/**
 * Trim all string properties of an object
 *
 * @param obj Object to trim
 */
export const getTrimmedData = (obj: any) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  Object.keys(obj).map((key) => {
    if (typeof obj[key] === 'object') {
      getTrimmedData(obj[key]);
    } else if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
  });

  return obj;
};

/**
 * Slugify a string
 *
 * @param {string} string (e.g "Lorem ipsum dolor")
 * @return {string} Slugified string (e.g. "lorem-ipsum-dolor")
 */
export const slugify = (string: string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  );
};

/**
 * Strip trailing slash
 *
 * @param {string} value (e.g "/foo/bar/")
 * @return {string} Value without trailing slash (e.g. "/foo/bar")
 */
export const stripTrailingSlash = (value: string) => {
  return value.endsWith('/') ? value.slice(0, -1) : value;
};

/**
 * Capitalize the first letter of a string
 *
 * @param {word} Value of the word
 */
export const capitalizeFirstLetter = (word?: string) => {
  if (word === undefined || word.length === 0) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const stripDiacritics = (string: string) => {
  string = string || '';
  return typeof string.normalize !== 'undefined' ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : string;
};
