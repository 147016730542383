import { useContext, useState } from 'react';
import Select from '../Select/Select';
import { UserTheme, UserThemeList } from '../../../features/users/enums/UserTheme';
import { AppContext } from '../../../bootstrap/App';

interface Props {
  setData: (data: Array<{ name: string; value: unknown }>) => void;
}

function WelcomeWizardTheme(props: Props) {
  const { setData } = props;

  const [value, setValue] = useState(UserTheme.Light);

  const { setDarkMode } = useContext(AppContext);

  const handleChange = async (newValue: UserTheme) => {
    setValue(newValue);

    await setData([{ name: 'theme', value: newValue }]);
    setDarkMode(newValue === UserTheme.Dark);
  };

  return (
    <Select
      id='theme'
      label='Theme'
      items={UserThemeList}
      value={value}
      onChange={(event) => handleChange(event.target.value as UserTheme)}
      required={true}
    />
  );
}

export default WelcomeWizardTheme;
