import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import { tokens } from '@ap/design-system-ts-tokens';

export const StyledCardMedia = styled(CardMedia)(({ theme }) => {
  const mobileBreakpoint = theme.breakpoints.down('md');

  return {
    width: '100px',
    height: '100px',
    borderRadius: tokens.density.standard.ref.radius.large,

    [mobileBreakpoint]: {
      width: '100%',
    },
  };
});
