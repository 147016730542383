export default interface Pagination {
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export const defaultPagination: Pagination = {
  totalItems: 0,
  itemsPerPage: 1,
  totalPages: 1,
  currentPage: 1,
};
