import { Fragment } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

/**
 * Sanitizes dirty HTML to prevents XSS attacks, then optionally converts the clean HTML string to one or more React elements.
 *
 * @param html
 * @param {boolean} convertToReactElements Set to TRUE to converts an HTML string to one or more React elements
 */
const parseHtml = (html: any, convertToReactElements = true): string | JSX.Element | JSX.Element[] => {
  const defaultConfig = {
    // Allow all safe HTML elements but neither SVG nor MathML
    USE_PROFILES: { html: true },
    // Add <style> elements to block-list
    FORBID_TAGS: ['style'],
    // Prohibit HTML5 data attributes
    ALLOW_DATA_ATTR: false,
  };

  const clean = DOMPurify.sanitize(html, defaultConfig);

  return convertToReactElements ? parse(clean) : clean;
};

/**
 * Remove all HTML but keep text.
 *
 * @param html
 * @param allowedTags
 */
const removeAllHtml = (html: any, allowedTags = ['']): string => {
  const defaultConfig = {
    // Remove all HTML elements
    ALLOWED_TAGS: [...['#text'], ...allowedTags],
    // Keep an element's content when the element is removed
    KEEP_CONTENT: true,
  };

  return DOMPurify.sanitize(html, defaultConfig);
};

/**
 * Parse an excerpt for output:
 * - replace line breaks with a space
 * - remove all HTML but keep text
 * - convert HTML strings directly into React components
 *
 * @param html
 */
const parseExcerpt = (html: any): JSX.Element | JSX.Element[] => {
  let htmlFormatted = html;

  if (html) {
    htmlFormatted = html.replace(',<br /><br />', ',&nbsp;&#32;'); // insert space + breaking space
    htmlFormatted = htmlFormatted.replace('<br /><br />', '&#32;'); // insert breaking space
  }

  const clean = removeAllHtml(htmlFormatted);

  return <Fragment>{parse(clean)}</Fragment>;
};

/**
 * Get the <body> content from an HTML string
 *
 * @param {string} html e.g. <html><bod<><p>Foo</p></bod<></html>
 * @return {string} e.g. <p>Foo</p>
 */
const extractBody = (html: string) => {
  const parser = new DOMParser();
  const domDocument = parser.parseFromString(html, 'text/html');
  const bodyElement = domDocument.getElementsByTagName('body')[0];
  return bodyElement.innerHTML.trim();
};

export { parseHtml, removeAllHtml, parseExcerpt, extractBody };
