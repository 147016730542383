import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/auth/msal';

/**
 * Custom hook to get an access token from MSAL instance.
 */
function useGetMsalAccessToken() {
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  /**
   * Get an access token from MSAL instance.
   *
   * @returns {Promise<string | null>} A promise that resolves to an access token or null if the operation fails
   */
  const getAccessToken = async () => {
    if (account && inProgress === InteractionStatus.None) {
      const loginRequestWithAccount = {
        account,
        ...loginRequest,
      };

      try {
        /**
         * Acquire an access token silently.
         *
         * - it first checks the cache in browser storage to see if a non-expired access token exists and returns it
         * - if no access token is found or the access token found has expired, it attempts to use its refresh token to
         * get a fresh access token
         * - if the refresh token's 24-hour lifetime has also expired, it opens a hidden iframe to silently request a
         * new authorization code which will then be exchanged for a fresh set of tokens
         */
        const result = await instance.acquireTokenSilent(loginRequestWithAccount);

        return result.accessToken;
      } catch (err) {
        /**
         * If acquireTokenSilent fails, fallback to acquireTokenRedirect
         *
         * The silent token requests to Microsoft Entra ID might fail for reasons (e.g password change, browser blocking
         * third party cookies, ...). In these cases, we invoke the popup interactive method (which may prompt the user)
         * to acquire new tokens.
         */
        if (err instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(loginRequestWithAccount);
        }
      }
    } else if (!account && inProgress === InteractionStatus.None) {
      return instance.acquireTokenRedirect(loginRequest);
    }

    return null;
  };

  return { getAccessToken };
}

export default useGetMsalAccessToken;
