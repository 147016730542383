import { JSX } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { APCard } from '@ap/design-system';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

interface Props {
  children: JSX.Element;
}

/**
 * This provider prevents the application from being loaded if the env variable of maintenance
 * is true
 */
function AppMaintenanceProvider(props: Props) {
  if ('true'.localeCompare(import.meta.env.VITE_MAINTENANCE_MODE) === 0) {
    return (
      <Container maxWidth='sm' sx={{ flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            padding: '32px',
          }}
        >
          <APCard>
            <CardHeader title='Maintenance' />
            <CardContent>
              <Typography gutterBottom>Sorry, we are down for scheduled maintenance right now.</Typography>
              <Typography>We except to be back shortly. Thanks for your patience.</Typography>
            </CardContent>
          </APCard>
        </Box>
      </Container>
    );
  }

  return props.children;
}

export default AppMaintenanceProvider;
