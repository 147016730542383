export enum UserRegionalFormat {
  ENGLISH_US = 'en-US',
  ENGLISH_UK = 'en-UK',
  FRENCH = 'fr-FR',
  FRENCH_CH = 'fr-CH',
  DEUTSCH = 'de',
}

export const UserRegionalFormatList = [
  { name: 'English (United States)', value: UserRegionalFormat.ENGLISH_US },
  { name: 'English (United Kingdom)', value: UserRegionalFormat.ENGLISH_UK },
  { name: 'Français (France)', value: UserRegionalFormat.FRENCH },
  { name: 'Français (Suisse)', value: UserRegionalFormat.FRENCH_CH },
  { name: 'Deutsch (Deutschland)', value: UserRegionalFormat.DEUTSCH },
];
