import { useContext, useEffect } from 'react';
import { LayoutContext } from '../Layout/Layout';
import BreadcrumbItem from './BreadcrumbItem';

function useSetBreadcrumbItems(items: BreadcrumbItem[]) {
  const { setBreadcrumbItems } = useContext(LayoutContext);

  useEffect(() => {
    setBreadcrumbItems(items);
  }, []);
}

export default useSetBreadcrumbItems;
