import { useContext, useState } from 'react';
import { AppContext } from '../../../bootstrap/App';
import RealmUser from '../../../models/RealmUser';

/**
 * Update the custom data of the current signed-in user
 */
function useSetCurrentUserData() {
  const { realmObject, realmUser, setRealmUser } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const setData = async (data: Array<{ name: string; value: unknown }>) => {
    setLoading(true);
    await realmObject?.functions.setRealmCustomUserData(data);

    // Refresh the user's local customData property
    const newRealmUser = (await realmObject?.refreshCustomData()) as RealmUser;
    setRealmUser({ ...newRealmUser, accessToken: realmUser?.accessToken });

    setLoading(false);
  };

  return { setData, loading };
}

export default useSetCurrentUserData;
