import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AlertToast from './AlertToast';
import useGetCurrentUser from '../../../features/users/services/useGetCurrentUser';
import { APBannerProps } from '@ap/design-system/dist/components/APBanner/types';
import { APBanner } from '@ap/design-system';

interface Props extends APBannerProps {
  /**
   * This property is required if enableCloseNeverShowAgain is set to TRUE
   */
  id?: string;
  enableClose?: boolean;
  enableCloseNeverShowAgain?: boolean;
}

function Alert(props: Props) {
  const { id, enableClose = false, enableCloseNeverShowAgain = true, ...rest } = props;
  const currentUser = useGetCurrentUser();
  const internalId = `message-${id}`;
  const [open, setOpen] = useState(
    id && enableCloseNeverShowAgain ? currentUser.alertShown.indexOf(internalId) < 0 : true,
  );

  const handleClose = (event: React.SyntheticEvent) => {
    setOpen(false);

    if (id && enableCloseNeverShowAgain) {
      // Ask user if he wants to never show this alert again
      toast.info((toastProps) => <AlertToast {...toastProps} />, {
        toastId: internalId,
        closeButton: false,
      });
    }

    if (props.onClose) {
      props.onClose(event);
    }
  };

  if (!open) {
    return null;
  }

  return (
    <APBanner
      {...rest}
      showIcon={enableClose ? true : props.showIcon}
      onClose={enableClose ? (event) => handleClose(event) : undefined}
    />
  );
}

export default Alert;
