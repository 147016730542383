import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  sx?: SxProps<Theme>;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      sx={sx ?? { flex: 1, width: '100%' }}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </Box>
  );
}

export default TabPanel;
