import { SelectItem } from '../../../template/components/Select/Select';

export enum UserRole {
  ADMIN = 'admin',
  AFFILIATE_ADMIN = 'affiliate-admin',
  COLLABORATOR_HQ = 'collaborator-hq',
  COLLABORATOR = 'collaborator',
  SUPPORT = 'support',
  // Specific roles used for the booking
  SALES_MANAGER = 'sales-manager',
  SALES_ASSOCIATE = 'sales-associate',
  WELCOME_HOST = 'welcome-host',
}

export const RoleList = [
  { value: UserRole.ADMIN, name: 'CRM HQ Admin' },
  { value: UserRole.AFFILIATE_ADMIN, name: 'Affiliate CRM Admin' },
  { value: UserRole.COLLABORATOR_HQ, name: 'HQ Collaborators' },
  { value: UserRole.COLLABORATOR, name: 'Affiliate & Collaborators' },
  { value: UserRole.SALES_MANAGER, name: 'Sales Managers' },
  { value: UserRole.SALES_ASSOCIATE, name: 'Sales Associates' },
  { value: UserRole.WELCOME_HOST, name: 'Welcome Hosts' },
  { value: UserRole.SUPPORT, name: 'Support' },
];

export const RoleListPublic = [
  { value: UserRole.ADMIN, name: 'Administrator' },
  { value: UserRole.AFFILIATE_ADMIN, name: 'Affiliate admin' },
  { value: UserRole.COLLABORATOR_HQ, name: 'HQ Collaborators' },
  { value: UserRole.COLLABORATOR, name: 'Collaborator' },
  { value: UserRole.SALES_MANAGER, name: 'Sales Manager' },
  { value: UserRole.SALES_ASSOCIATE, name: 'Sales Associate' },
  { value: UserRole.WELCOME_HOST, name: 'Welcome Host' },
  { value: UserRole.SUPPORT, name: 'Support' },
];

export const RoleListWithGrouping: SelectItem[] = [
  { isListSubHeader: true, name: 'AP Events' },
  { value: UserRole.ADMIN, name: 'CRM HQ Admin' },
  { value: UserRole.AFFILIATE_ADMIN, name: 'Affiliate CRM Admin' },
  { value: UserRole.COLLABORATOR_HQ, name: 'HQ Collaborators' },
  { value: UserRole.COLLABORATOR, name: 'Affiliate & Collaborators' },
  { isListSubHeader: true, name: 'AP Calendar' },
  { value: UserRole.SALES_MANAGER, name: 'Sales Managers' },
  { value: UserRole.SALES_ASSOCIATE, name: 'Sales Associates' },
  { value: UserRole.WELCOME_HOST, name: 'Welcome Hosts' },
  { isListSubHeader: true, name: 'Other' },
  { value: UserRole.SUPPORT, name: 'Support' },
];
