import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledContent = styled(Box)(({ theme }) => {
  const mobileBreakpoint = theme.breakpoints.down('md');

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    [mobileBreakpoint]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };
});
