import { useLazyQuery } from '@apollo/client';
import { GET_SUBEVENTS_GLOBAL } from './query';
import SubEvent from '../../models/SubEvent';

interface QueryData {
  subEvents: SubEvent[];
}

interface QueryVars {
  query: {
    _partition_in: string[];
  };
}

/**
 * Fetch sub events of the specified master events
 */
function useFetchSubEventsGlobal() {
  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVars>(
    GET_SUBEVENTS_GLOBAL,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const fetchSubEvents = async (masterEventIds: string[]) => {
    const query: QueryVars = {
      query: {
        _partition_in: masterEventIds,
      },
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return response.data?.subEvents || [];
  };

  return {
    fetchSubEvents,
    loading,
    error,
    subEvents: data?.subEvents || undefined,
  };
}

export default useFetchSubEventsGlobal;
