import { stripTrailingSlash } from './string';

/**
 * Get the last URI segment.
 * e.g. for the URI "/example/foo/bar" or "/example/foo/bar/", it will returns "bar"
 */
function getLastUriSegment(): string {
  const url = stripTrailingSlash(window.location.pathname);

  return url.substring(url.lastIndexOf('/') + 1);
}

export { getLastUriSegment };
