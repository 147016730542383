import axios from 'axios';
import useGetMsalAccessToken from '../features/users/services/useGetMsalAccessToken';
import { useEffect } from 'react';

const headers = {
  Accept: 'application/json',
};

const eventsApiClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/`,
  headers,
});

const useEventsApiClient = () => {
  const { getAccessToken } = useGetMsalAccessToken();

  useEffect(() => {
    const reqInterceptor = eventsApiClient.interceptors.request.use(async (config) => {
      const accessToken = await getAccessToken();
      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    });

    // Cleanup function
    return () => {
      eventsApiClient.interceptors.request.eject(reqInterceptor);
    };
  }, [getAccessToken]);
  return { apiClient: eventsApiClient };
};

export default useEventsApiClient;
