import { JSX, useContext, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { AppContext } from './App';
import getRealmUser, { getRealmObject } from '../config/realm/getRealmUser';
import RealmUser from '../models/RealmUser';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { APProgressLoading } from '@ap/design-system';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { setContext } from '@apollo/client/link/context';
import useGetMsalAccessToken from '../features/users/services/useGetMsalAccessToken';
import usePrevious from '../helper/usePrevious';
import getApolloClient from '../config/apollo/getApolloClient';

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  flexDirection: 'column',
  gap: '16px',
  height: '100%',
  padding: '32px',
};

interface Props {
  children: JSX.Element;
}

function AppRealmProvider(props: Props) {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const prevAccessToken = usePrevious(accessToken);
  const { getAccessToken } = useGetMsalAccessToken();

  const { realmUser, setRealmUser, realmObject, setRealmObject, apolloClient, setApolloClient } =
    useContext(AppContext);

  useEffect(() => {
    instance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && accessToken !== prevAccessToken)
      ) {
        const response = event.payload as AuthenticationResult;

        if (response) {
          setAccessToken(response.accessToken);
        }
      }
    });
  }, []);

  const withToken = setContext(async (_, { headers }) => {
    const token = await getAccessToken();

    let localRealmUser;
    if (token) {
      localRealmUser = (await getRealmUser(token)) as RealmUser;
      setRealmUser(localRealmUser);
    }
    return {
      headers: {
        ...headers,
        Authorization: localRealmUser ? `Bearer ${localRealmUser.accessToken}` : null,
      },
    };
  });

  useEffect(() => {
    const initializeRealm = async () => {
      if (!accessToken) {
        return;
      }

      try {
        // Use the IDP access token to authenticate to Realm JWT auth provider
        const localRealmUser = (await getRealmUser(accessToken)) as RealmUser;

        if (localRealmUser) {
          if (accessToken == realmUser?.accessToken) {
            // Nothing to do (same token)
            return;
          }

          const localApolloClient = await getApolloClient(withToken);
          setRealmUser(localRealmUser);
          setApolloClient(localApolloClient);

          const localRealmObject = getRealmObject();
          if (localRealmObject && localRealmObject !== realmObject) {
            setRealmObject(localRealmObject);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (accessToken && (!realmUser || !apolloClient) && accessToken != prevAccessToken) {
      initializeRealm();
    }
  }, [accessToken, realmUser]);

  if (!realmUser || !apolloClient) {
    return (
      <Container maxWidth='sm' sx={{ flex: 1 }}>
        <Box sx={containerStyle}>
          <APProgressLoading>Initiate database connection...</APProgressLoading>
        </Box>
      </Container>
    );
  }

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
}

export default AppRealmProvider;
