import { isEqual, isBefore, isAfter, format, formatDistanceToNow } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export interface DateProps {
  date?: Date;
  formatStr?: string;
  fromNow?: boolean;
  showTime?: boolean;
  removeYearFromLocalizedDate?: boolean;
  timeZone?: string;
}

export const formatDate = (props: DateProps, regionalFormat: Locale) => {
  const {
    date,
    formatStr = 'P',
    fromNow = false,
    showTime = false,
    removeYearFromLocalizedDate = false,
    timeZone,
  } = props;

  if (!date) {
    return undefined;
  }

  const formatStrFinal = showTime ? `${formatStr} 'at' p` : formatStr;

  let output = format(date, formatStrFinal, { locale: regionalFormat });
  if (timeZone) {
    output = formatInTimeZone(date, timeZone, formatStrFinal, { locale: regionalFormat });
  }

  if (removeYearFromLocalizedDate && ['P', 'PP', 'PPP', 'PPPP'].includes(formatStr)) {
    output = output.replace(',', '').replace(date.getFullYear().toString(), '').trim();
  }

  if (fromNow) {
    output = `${output} (${formatDistanceToNow(date, { includeSeconds: false })})`;
  }

  return output;
};

export const isBetween = (date: Date, from: Date, to: Date, inclusivity: '()' | '[]' | '(]' | '[)' = '()') => {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[';
  const isAfterEqual = inclusivity[1] === ']';

  return (
    (isBeforeEqual ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};
