import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { APBannerSeverity } from '@ap/design-system/dist/components/APBanner/types';
import { APDrawer, APDrawerContent, APIcon } from '@ap/design-system';
import useBreakpointUp from '../../../../template/hooks/useBreakpointUp';
import useDeleteNotification from '../../services/useDeleteNotification';
import Notification, { NotificationParams } from '../../models/Notification';
import DateFormat from '../../../../template/components/DateFormat/DateFormat';
import Alert from '../../../../template/components/Alert/Alert';
import { NotificationSeverity } from '../../enums/NotificationSeverity';

// Design system's severity is different from the notification's severity
function getAlertSeverityFromNotificationSeverity(severity?: NotificationSeverity): APBannerSeverity | undefined {
  switch (severity) {
    case NotificationSeverity.SUCCESS:
      return 'success';
    case NotificationSeverity.INFO:
      return 'neutral';
    case NotificationSeverity.WARNING:
      return 'warning';
    case NotificationSeverity.DANGER:
      return 'danger';
    default:
      return undefined;
  }
}

interface Props {
  open: boolean;
  notifications: Notification[];
  onClose: () => void;
}

function NotificationDrawer(props: Props) {
  const { open, onClose, notifications } = props;

  const largeScreen = useBreakpointUp('sm');

  const { deleteNotification } = useDeleteNotification();

  const replaceText = (message: string | undefined, params: NotificationParams[] | undefined): string => {
    params?.forEach((param) => {
      message = message?.replace('{' + param.name + '}', param.value);
    });

    return message ?? '';
  };

  return (
    <APDrawer anchor='right' open={open} onClose={onClose} PaperProps={largeScreen ? { sx: { minWidth: '25vw' } } : {}}>
      <APDrawerContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='title3' mb={6}>
          Notifications
        </Typography>
        <Box sx={{ flex: 1, pb: 6 }}>
          {notifications.length === 0 ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='1'>
              <Stack alignItems='center'>
                <APIcon name='NotificationsOff' />
                <Typography variant='footnoteMedium' mt={3}>
                  No new notifications
                </Typography>
              </Stack>
            </Box>
          ) : (
            <Fragment>
              {notifications.map((notification, index) => (
                <Alert
                  key={index}
                  severity={getAlertSeverityFromNotificationSeverity(notification.severity)}
                  sx={{ mb: 3 }}
                  onClose={() => deleteNotification(notification)}
                  enableClose
                  showIcon
                >
                  <Typography>{replaceText(notification.message, notification.params)}</Typography>
                  <Typography variant='caption' display='block' sx={{ mt: 2 }}>
                    <DateFormat date={new Date(notification.createdAt)} />
                  </Typography>
                </Alert>
              ))}
            </Fragment>
          )}
        </Box>
      </APDrawerContent>
    </APDrawer>
  );
}

export default NotificationDrawer;
