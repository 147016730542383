import { gql } from '@apollo/client';

export const GET_MASTER_EVENTS = gql`
  query findMasterEventsQuery($input: FindMasterEventsQueryResolverInput!) {
    result: findMasterEventsQuery(input: $input) {
      data {
        _id
        name
        createdBy
        startAt
        country {
          _id
        }
        endAt
        users {
          externalUserId
          type
        }
        type {
          _id
        }
        coverImage {
          urlRegular
        }
      }
      pagination {
        currentPage
        itemsPerPage
        totalPages
        totalItems
      }
    }
  }
`;

export const GET_MASTER_EVENT = gql`
  query masterEvent($query: MasterEventQueryInput) {
    masterEvent(query: $query) {
      _id
      name
      startAt
      endAt
      createdAt
      createdBy
      hasVirtualKeyboard
      campaignId
      country {
        _id
        translations {
          _id
          name
        }
      }
      users {
        externalUserId
        displayName
        type
      }
      ipads
      language {
        _id
        name
      }
      userLanguage {
        _id
        name
      }
      card
      secondCard
      type {
        _id
        name
      }
      coverImage {
        unsplashId
        unsplashLink
        unsplashUserName
        unsplashUserUrl
        urlFull
        urlRegular
      }
      widgets {
        _id
        content {
          options
          type
        }
        layouts {
          desktop {
            height
            positionX
            positionY
            preCollapseHeight
            width
          }
          mobile {
            height
            positionX
            positionY
            preCollapseHeight
            width
          }
        }
        movable
        resizable
        title
      }
    }
  }
`;

export const GET_MASTER_EVENT_GUESTS_STATS = gql`
  query masterEventGuestsStats($query: MasterEventQueryInput) {
    masterEvent(query: $query) {
      _id
      guests {
        total
        totalRsvped
        totalCheckedIn
        totalCheckedOut
      }
    }
  }
`;

export const GET_MASTER_EVENT_TYPES = gql`
  query masterDataEventTypes {
    result: masterDataEventTypes {
      _id
      name
    }
  }
`;

export const CREATE_OR_UPDATE_MASTER_EVENT = gql`
  mutation upsertOneMasterEvent($query: MasterEventQueryInput!, $data: MasterEventInsertInput!) {
    result: upsertOneMasterEvent(query: $query, data: $data) {
      _id
    }
  }
`;

export const DELETE_MASTER_EVENT = gql`
  mutation deleteOneMasterEvent($query: MasterEventQueryInput!) {
    deleteOneMasterEvent(query: $query) {
      _id
    }
  }
`;

export const UPDATE_MASTER_EVENT_WIDGETS = gql`
  mutation updateOneMasterEvent($query: MasterEventQueryInput!, $set: MasterEventUpdateInput!) {
    result: updateOneMasterEvent(query: $query, set: $set) {
      _id
    }
  }
`;
