import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HorizontalNavigationItems } from './HorizontalNavigationItem';
import { APTab, APTabs } from '@ap/design-system';
import Box from '@mui/material/Box';

interface Props {
  value?: any;
  items: HorizontalNavigationItems;
  onChange?: (event: React.SyntheticEvent, value: any) => void;
  classes?: string[];
}

function HorizontalNavigation(props: Props) {
  const { value = 0, items, onChange, classes = [] } = props;

  const [currentValue, setValue] = useState(value);
  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  // Update when value from parent change
  // Use case: history back
  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange =
    onChange ||
    ((event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      const { path } = items[newValue];

      if (path !== undefined) {
        navigate(path);
      }
    });

  if (!Array.isArray(items) || items.length === 0) {
    return <Fragment />;
  }

  return (
    <Box sx={{ width: '100%' }} className={`${classes.join(' ')}`}>
      <APTabs
        value={currentValue}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons={desktop ? 'auto' : true}
        allowScrollButtonsMobile={!desktop}
      >
        {items.map((item, index) => (
          <APTab
            key={index}
            value={item?.value ?? index}
            label={item.label}
            disabled={item.disabled}
            title={item.title}
          />
        ))}
      </APTabs>
    </Box>
  );
}

export default HorizontalNavigation;
