import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { AppContext } from '../../../bootstrap/App';
import { DateProps, formatDate } from '../../../helper/date';
import { stripTrailingSlash } from '../../../helper/string';

interface Props extends DateProps {
  userSelect?:
    | 'none'
    | 'auto'
    | 'text'
    | 'contain'
    | 'all'
    | 'unset'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer';
  element?: React.ElementType;
}

function DateFormat(props: Props) {
  const { userSelect = 'none', element = 'div', ...rest } = props;

  const { regionalFormat } = useContext(AppContext);

  return (
    <Box component={element} sx={{ display: 'inline', ...(userSelect && { userSelect }) }}>
      {stripTrailingSlash(formatDate(rest, regionalFormat) ?? '')}
    </Box>
  );
}

export default DateFormat;
