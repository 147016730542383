import { Locale } from 'date-fns';
import { de, enGB, fr, frCH } from 'date-fns/locale';
import getDefaultDateFnsLocale from './getDefaultDateFnsLocale';
import getCustomEnUsLocale from './getCustomEnUsLocale';

const enUs = getCustomEnUsLocale();

function getDateFnsLocaleFromString(locale: string): Locale {
  switch (locale) {
    case 'de':
      return de;
    case 'fr-FR':
      return fr;
    case 'fr-CH':
      return frCH;
    case 'en-UK':
      return enGB;
    case 'en-US':
      return enUs;
    default:
      return getDefaultDateFnsLocale();
  }
}

export default getDateFnsLocaleFromString;
