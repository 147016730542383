import { gql } from '@apollo/client';

export const NOTIFICATION_FIELDS_FRAGMENT = gql`
  fragment NotificationFields on Notification {
    _id
    _partition
    group
    message
    severity
    params {
      name
      value
    }
    createdAt
    modifiedAt
    createdBy
    modifiedBy
  }
`;

export const GET_NOTIFICATIONS = gql`
  ${NOTIFICATION_FIELDS_FRAGMENT}
  query notifications($query: NotificationQueryInput!, $limit: Int = 0, $sortBy: NotificationSortByInput) {
    notifications(query: $query, sortBy: $sortBy, limit: $limit) {
      ...NotificationFields
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation insertOneNotification($data: NotificationInsertInput!) {
    result: insertOneNotification(data: $data) {
      _id
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation updateManyNotifications($query: NotificationQueryInput, $set: NotificationUpdateInput!) {
    result: updateManyNotifications(query: $query, set: $set) {
      matchedCount
      modifiedCount
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteManyNotifications($query: NotificationQueryInput!) {
    deleteOneNotification(query: $query) {
      _id
    }
  }
`;
