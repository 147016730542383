import { JSX, Fragment, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

function AppRouterProvider(props: Props) {
  /**
   * On mount, check the session storage for a redirection URI. If it exists, redirect programmatically the user
   * to the corresponding URI. Then, the redirection URI is removed to prevent infinite redirection.
   *
   * This redirection URI has been persisted when the user signed-in (see App.tsx) by the IDP. The IDP cannot hold
   * a reference for us to the expected redirection page, therefore we manually have to perform this task.
   */
  useEffect(() => {
    const redirectURI = sessionStorage.getItem('redirectURI');
    sessionStorage.removeItem('redirectURI');

    if (redirectURI && redirectURI !== location.href) {
      location.replace(redirectURI);
    }
  }, []);

  return (
    <Suspense fallback={<Fragment />}>
      <BrowserRouter>{props.children}</BrowserRouter>
    </Suspense>
  );
}

export default AppRouterProvider;
