export enum UserPermission {
  BLOG_READ = 'blogRead',
  BLOG_CREATE = 'blogCreate',
  BLOG_UPDATE = 'blogUpdate',
  BLOG_DELETE = 'blogDelete',

  DEVICES_MANAGE = 'devicesManage',

  USERS_MANAGE = 'usersManage',

  REPORTING = 'reporting',

  EVENTS_VIEW_ALL = 'eventsViewAll',
  EVENT_CREATE = 'eventCreate',
  EVENT_CREATE_NON_STANDARD = 'eventCreateNonStandard',
  EVENT_UPDATE = 'eventUpdate',
  EVENT_DELETE = 'eventDelete',

  EVENT_WIDGET_CREATE = 'eventWidgetCreate',
  EVENT_WIDGET_UPDATE = 'eventWidgetUpdate',
  EVENT_WIDGET_DELETE = 'eventWidgetDelete',

  EVENT_TAG_READ = 'eventTagRead',
  EVENT_TAG_CREATE = 'eventTagCreate',
  EVENT_TAG_UPDATE = 'eventTagUpdate',
  EVENT_TAG_DELETE = 'eventTagDelete',

  EVENT_SUB_READ = 'eventSubRead',
  EVENT_SUB_CREATE = 'eventSubCreate',
  EVENT_SUB_UPDATE = 'eventSubUpdate',
  EVENT_SUB_DELETE = 'eventSubDelete',
  EVENT_SUB_WALLET = 'eventSubWallet',

  EVENT_GUEST_LIST_READ = 'eventGuestListRead',
  EVENT_GUEST_LIST_CREATE = 'eventGuestListCreate',
  EVENT_GUEST_LIST_UPDATE = 'eventGuestListUpdate',
  EVENT_GUEST_LIST_DELETE = 'eventGuestListDelete',
  EVENT_GUEST_READ = 'eventGuestRead',
  EVENT_GUEST_CREATE = 'eventGuestCreate',
  EVENT_GUEST_UPDATE = 'eventGuestUpdate',
  EVENT_GUEST_DELETE = 'eventGuestDelete',
  EVENT_GUEST_EXPORT = 'eventGuestExport',
  EVENT_GUEST_PUSH_CRM = 'eventGuestPushCrm',

  BOOKING_CREATE = 'bookingCreate',
  BOOKING_UPDATE = 'bookingUpdate',
  BOOKING_DELETE = 'bookingDelete',
  BOOKING_DISABLE = 'bookingDisable',
  BOOKING_VALIDATE = 'bookingValidate',

  BOOKING_CALENDAR_MASTER_READ = 'bookingCalendarMasterRead',
  BOOKING_CALENDAR_OTHER_READ = 'bookingCalendarOtherRead',
  BOOKING_CALENDAR_OWN_BOOKING_UPDATE = 'bookingCalendarOwnBookingUpdate',
  BOOKING_CALENDAR_OWN_BOOKING_DELETE = 'bookingCalendarOwnBookingDelete',
  BOOKING_CALENDAR_OTHER_BOOKING_UPDATE = 'bookingCalendarOtherBookingUpdate',
  BOOKING_CALENDAR_OTHER_BOOKING_DELETE = 'bookingCalendarOtherBookingDelete',
  BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL = 'bookingCalendarResendConfirmationEmail',

  BOOKING_SETTINGS_READ = 'bookingSettingsRead',
  BOOKING_SETTINGS_UPDATE = 'bookingSettingsUpdate',

  BOOKING_TEMPLATE_READ = 'bookingTemplateRead',
  BOOKING_TEMPLATE_UPDATE = 'bookingTemplateUpdate',
  BOOKING_MANAGE_GOOGLE_CAMPAIGN = 'bookingGoogleCampaign',

  BOOKING_SETTINGS_MANAGE_LANGUAGES = 'bookingSettingsManageLanguages',
  BOOKING_SETTINGS_ADVANCED_SCHEDULING = 'bookingSettingsAdvancedScheduling',
  BOOKING_SETTINGS_ADVANCED_FEATURES = 'bookingSettingsAdvancedFeatures',

  BOOKING_TARGETS_READ = 'bookingTargetsRead',
  BOOKING_TARGETS_CREATE = 'bookingTargetsCreate',
  BOOKING_TARGETS_UPDATE = 'bookingTargetsUpdate',
  BOOKING_TARGETS_DELETE = 'bookingTargetsDelete',
}
