import { useRef } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { GET_MASTER_EVENT } from './query';
import { setCountryName } from '../../../countries/services/useFetchCountries';
import MasterEvent, { MasterEventQueryInput } from '../../models/MasterEvent';
import { Language } from '../../../languages/enums/language.enum';

interface QueryData {
  masterEvent: MasterEvent;
}

interface QueryVariables {
  query: Pick<MasterEventQueryInput, '_id'>;
}

/**
 * Fetch a specific master event
 */
function useFetchMasterEvent() {
  const isNotFound = useRef(false);
  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVariables>(
    GET_MASTER_EVENT,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const formatData = (data?: QueryData): MasterEvent | undefined => {
    if (!data) {
      return undefined;
    }

    if (!data.masterEvent) {
      isNotFound.current = true;
      return undefined;
    }

    const setLocaleCountryName = setCountryName(Language.English);

    const { startAt, endAt, country, card } = data.masterEvent;
    return {
      ...data.masterEvent,
      country: country ? setLocaleCountryName(country) : undefined,
      startAt: moment(startAt).toDate(),
      endAt: moment(endAt).toDate(),
      // TODO Split -
      /*      card: {
        ...(card as CRMCard),
        country: card?.country ? setLocaleCountryName(card?.country) : undefined,
      },*/
    };
  };

  const fetchMasterEvent = async (masterEventId: string) => {
    const query = {
      query: {
        _id: masterEventId,
      },
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return formatData(response.data);
  };

  return {
    fetchMasterEvent,
    loading,
    error,
    isNotFound: isNotFound.current,
    masterEvent: formatData(data),
  };
}

export default useFetchMasterEvent;
