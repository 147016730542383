import { useTheme, Breakpoint } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Listens for matches to a CSS media query / breakpoint (xs, sm, md, lg or xl).
 *
 * It allows the rendering of components based on whether the query matches or not.
 * It's performant, it observes the document to detect when its media queries change, instead of polling the values periodically.
 *
 * @link https://material-ui.com/components/use-media-query/
 * @link https://material-ui.com/customization/breakpoints/#theme-breakpoints-up-key-media-query
 *
 * @param {string|number} breakpoint A breakpoint key (xs, sm, md, lg or xl) or a screen width number in pixels
 * @return {boolean}
 */
function useBreakpointUp(breakpoint: Breakpoint | number): boolean {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.up(breakpoint), { noSsr: true });
}

export default useBreakpointUp;
