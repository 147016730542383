import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_SUBEVENT = gql`
  mutation upsertOneSubEvent($query: SubEventQueryInput!, $data: SubEventInsertInput!) {
    result: upsertOneSubEvent(query: $query, data: $data) {
      _id
    }
  }
`;

export const DELETE_SUBEVENTS = gql`
  mutation deleteManySubEvents($query: SubEventQueryInput!) {
    deleteManySubEvents(query: $query) {
      deletedCount
    }
  }
`;

export const GET_SUBEVENT = gql`
  query subEvent($query: SubEventQueryInput!) {
    subEvent(query: $query) {
      _id
      name
      shortName
      startAt
      endAt
      enableCheckOut
      enableWalletPass
      locationLatitude
      locationLongitude
      locationFormattedAddress
      pushCrmTargetList
      createdAt
      createdBy
      modifiedAt
      modifiedBy
      timezone: timezoneId {
        _id
        name
      }
    }
  }
`;

export const GET_SUBEVENTS = gql`
  query subEvents($query: SubEventQueryInput!, $sortBy: SubEventSortByInput, $limit: Int = 0) {
    subEvents(query: $query, sortBy: $sortBy, limit: $limit) {
      _id
      name
      shortName
      startAt
      endAt
      enableCheckOut
      enableWalletPass
      locationLatitude
      locationLongitude
      locationFormattedAddress
      pushCrmTargetList
      createdAt
      createdBy
      timezone: timezoneId {
        _id
        name
      }
    }
  }
`;

export const GET_SUBEVENTS_FILTERS = gql`
  query subEvents($query: SubEventQueryInput!) {
    subEvents(query: $query, sortBy: SHORTNAME_ASC) {
      _id
      shortName
    }
  }
`;

export const GET_SUBEVENTS_GLOBAL = gql`
  query subEvents($query: SubEventQueryInput!) {
    subEvents(query: $query, sortBy: SHORTNAME_ASC) {
      _id
      _partition
      shortName
    }
  }
`;
