export enum Language {
  German = 'de',
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  Italian = 'it',
  Russian = 'ru',
  Japanese = 'jp',
  ChineseSimplified = 'zs',
  ChineseTraditional = 'zt',
}
