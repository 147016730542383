import { JSX, useContext, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { APThemeProvider, getTheme } from '@ap/design-system';
import { AppContext } from './App';
import '../template/assets/style/main.scss';

interface Props {
  children: JSX.Element;
}

interface Props2 {
  children: JSX.Element;
  /**
   * The theme to use for the design system.
   */
  theme: 'light' | 'dark';
}

function AppThemeProvider2(props: Props2) {
  const theme = useMemo(() => {
    return getTheme(props.theme);
  }, [props.theme]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

function AppThemeProvider(props: Props) {
  const { regionalFormat, darkMode } = useContext(AppContext);

  return (
    <AppThemeProvider2 theme={darkMode ? 'dark' : 'light'}>
      <APThemeProvider theme={darkMode ? 'dark' : 'light'}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={regionalFormat}>
          {props.children}
        </LocalizationProvider>
      </APThemeProvider>
    </AppThemeProvider2>
  );
}

export default AppThemeProvider;
