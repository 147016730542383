import { JSX, useEffect, useState } from 'react';
import { MsalProvider, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  AccountInfo,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { APButton, APCard, APProgressLoading } from '@ap/design-system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { loginRequest, msalConfig } from '../config/auth/msal';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component.
 * It's initialized outside of the component tree to ensure it is not re-initialized on re-renders.
 */
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload && event.payload?.account) {
      // @ts-ignore
      const account = event.payload.account as AccountInfo;
      msalInstance.setActiveAccount(account);
    }
  });
});

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  flexDirection: 'column',
  gap: '16px',
  height: '100%',
  padding: '32px',
};

function Error() {
  const handleClickReload = () => {
    // Clear local and session storage
    sessionStorage.clear();
    localStorage.clear();

    // Remove all OIDC query parameters from the URL
    const url = new URL(window.location.href);
    url.searchParams.delete('code');
    url.searchParams.delete('state');

    // Redirect to the base URL
    window.location.href = url.href;
  };

  return (
    <Container maxWidth='sm' sx={{ flex: 1 }}>
      <Box sx={containerStyle}>
        <APCard>
          <CardHeader title='An error occurred while signing you in' />
          <CardContent>
            <Typography gutterBottom>
              Try signing in again by clicking the button below, refreshing the page, or opening the application in a
              new private window. If the problem persists, please contact the Service Desk.
            </Typography>
            <Typography variant='footnote'>Error message: unknown error</Typography>
          </CardContent>
          <CardActions>
            <APButton autoFocus onClick={handleClickReload}>
              Reload page
            </APButton>
          </CardActions>
        </APCard>
      </Box>
    </Container>
  );
}

function Loading() {
  return (
    <Container maxWidth='sm' sx={{ flex: 1 }}>
      <Box sx={containerStyle}>
        <APProgressLoading>Authentication in progress...</APProgressLoading>
      </Box>
    </Container>
  );
}

interface Props {
  children: JSX.Element;
}

function AppAuthContent(props: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    (async () => {
      if (!account || accounts.length === 0) {
        return;
      }

      if (!loading && inProgress === InteractionStatus.None) {
        const loginRequestWithAccount = {
          account,
          ...loginRequest,
        };

        try {
          /**
           * Acquire an access token silently.
           *
           * - it first checks the cache in browser storage to see if a non-expired access token exists and returns it
           * - if no access token is found or the access token found has expired, it attempts to use its refresh token to
           * get a fresh access token
           * - if the refresh token's 24-hour lifetime has also expired, it opens a hidden iframe to silently request a
           * new authorization code which will then be exchanged for a fresh set of tokens
           */
          await instance.acquireTokenSilent(loginRequestWithAccount);

          setLoading(false);
        } catch (e) {
          /**
           * If acquireTokenSilent fails, fallback to acquireTokenRedirect
           *
           * The silent token requests to Microsoft Entra ID might fail for reasons (e.g password change, browser blocking
           * third party cookies, ...). In these cases, we invoke the popup interactive method (which may prompt the user)
           * to acquire new tokens.
           */
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(loginRequestWithAccount);
          }

          console.error(e);
          setError(true);
        }
      }
    })();
  }, [inProgress, accounts, instance, loading]);

  if (loading || inProgress === InteractionStatus.Login) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return props.children;
}

function AppAuthProvider(props: Props) {
  return (
    <MsalProvider instance={msalInstance}>
      <AppAuthContent>{props.children}</AppAuthContent>
    </MsalProvider>
  );
}

export default AppAuthProvider;
