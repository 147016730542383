import { UserPermission } from '../enums/UserPermission';
import { UserRole } from '../enums/UserRole';

export default function (role: UserRole): UserPermission[] {
  switch (role) {
    case UserRole.SUPPORT:
      return [UserPermission.USERS_MANAGE, UserPermission.BLOG_READ];
    case UserRole.ADMIN:
      return [
        // TODO not sure EVENTS_VIEW_ALL should be managed on the app -> should be manage on DB?
        UserPermission.EVENTS_VIEW_ALL,

        // Devices CRUD ?
        UserPermission.DEVICES_MANAGE,
        // TODO split crud ?
        UserPermission.USERS_MANAGE,

        UserPermission.REPORTING,

        UserPermission.BLOG_READ,
        UserPermission.BLOG_CREATE,
        UserPermission.BLOG_UPDATE,
        UserPermission.BLOG_DELETE,

        UserPermission.EVENT_CREATE,
        UserPermission.EVENT_CREATE_NON_STANDARD,
        UserPermission.EVENT_UPDATE,
        UserPermission.EVENT_DELETE,

        UserPermission.EVENT_WIDGET_CREATE,
        UserPermission.EVENT_WIDGET_UPDATE,
        UserPermission.EVENT_WIDGET_DELETE,

        UserPermission.EVENT_SUB_WALLET,

        UserPermission.EVENT_SUB_READ,
        UserPermission.EVENT_SUB_CREATE,
        UserPermission.EVENT_SUB_UPDATE,
        UserPermission.EVENT_SUB_DELETE,

        UserPermission.EVENT_GUEST_LIST_READ,
        UserPermission.EVENT_GUEST_LIST_CREATE,
        UserPermission.EVENT_GUEST_LIST_UPDATE,
        UserPermission.EVENT_GUEST_LIST_DELETE,

        UserPermission.EVENT_TAG_READ,
        UserPermission.EVENT_TAG_CREATE,
        UserPermission.EVENT_TAG_UPDATE,
        UserPermission.EVENT_TAG_DELETE,

        UserPermission.EVENT_GUEST_READ,
        UserPermission.EVENT_GUEST_CREATE,
        UserPermission.EVENT_GUEST_UPDATE,
        UserPermission.EVENT_GUEST_DELETE,

        UserPermission.EVENT_GUEST_EXPORT,
        UserPermission.EVENT_GUEST_PUSH_CRM,

        UserPermission.BOOKING_CREATE,
        UserPermission.BOOKING_UPDATE,
        UserPermission.BOOKING_DELETE,
        UserPermission.BOOKING_DISABLE,
        UserPermission.BOOKING_VALIDATE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_SETTINGS_MANAGE_LANGUAGES,
        UserPermission.BOOKING_SETTINGS_ADVANCED_SCHEDULING,
        UserPermission.BOOKING_SETTINGS_ADVANCED_FEATURES,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_MANAGE_GOOGLE_CAMPAIGN,
        UserPermission.BOOKING_TARGETS_READ,
        UserPermission.BOOKING_TARGETS_CREATE,
        UserPermission.BOOKING_TARGETS_UPDATE,
        UserPermission.BOOKING_TARGETS_DELETE,
      ];
    case UserRole.AFFILIATE_ADMIN:
      return [
        UserPermission.BLOG_READ,

        UserPermission.EVENT_CREATE,
        UserPermission.EVENT_UPDATE,
        UserPermission.EVENT_DELETE,

        UserPermission.EVENT_WIDGET_CREATE,
        UserPermission.EVENT_WIDGET_UPDATE,
        UserPermission.EVENT_WIDGET_DELETE,

        UserPermission.EVENT_SUB_WALLET,

        UserPermission.EVENT_SUB_READ,
        UserPermission.EVENT_SUB_CREATE,
        UserPermission.EVENT_SUB_UPDATE,
        UserPermission.EVENT_SUB_DELETE,

        UserPermission.EVENT_GUEST_LIST_READ,
        UserPermission.EVENT_GUEST_LIST_CREATE,
        UserPermission.EVENT_GUEST_LIST_UPDATE,
        UserPermission.EVENT_GUEST_LIST_DELETE,

        UserPermission.EVENT_TAG_READ,
        UserPermission.EVENT_TAG_CREATE,
        UserPermission.EVENT_TAG_UPDATE,
        UserPermission.EVENT_TAG_DELETE,

        UserPermission.EVENT_GUEST_READ,
        UserPermission.EVENT_GUEST_CREATE,
        UserPermission.EVENT_GUEST_UPDATE,
        UserPermission.EVENT_GUEST_DELETE,

        UserPermission.EVENT_GUEST_EXPORT,
        UserPermission.EVENT_GUEST_PUSH_CRM,

        UserPermission.BOOKING_CREATE,
        UserPermission.BOOKING_UPDATE,
        UserPermission.BOOKING_DELETE,
        UserPermission.BOOKING_DISABLE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,
        UserPermission.BOOKING_SETTINGS_ADVANCED_SCHEDULING,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_TARGETS_READ,
        UserPermission.BOOKING_TARGETS_CREATE,
        UserPermission.BOOKING_TARGETS_UPDATE,
        UserPermission.BOOKING_TARGETS_DELETE,
      ];
    case UserRole.COLLABORATOR_HQ:
      return [
        UserPermission.BLOG_READ,

        UserPermission.EVENT_CREATE,
        UserPermission.EVENT_UPDATE,

        UserPermission.EVENT_WIDGET_CREATE,
        UserPermission.EVENT_WIDGET_UPDATE,
        UserPermission.EVENT_WIDGET_DELETE,

        UserPermission.EVENT_SUB_WALLET,

        UserPermission.EVENT_SUB_READ,
        UserPermission.EVENT_SUB_CREATE,
        UserPermission.EVENT_SUB_UPDATE,
        UserPermission.EVENT_SUB_DELETE,

        UserPermission.EVENT_GUEST_LIST_READ,
        UserPermission.EVENT_GUEST_LIST_CREATE,
        UserPermission.EVENT_GUEST_LIST_UPDATE,
        UserPermission.EVENT_GUEST_LIST_DELETE,

        UserPermission.EVENT_TAG_READ,
        UserPermission.EVENT_TAG_CREATE,
        UserPermission.EVENT_TAG_UPDATE,
        UserPermission.EVENT_TAG_DELETE,

        UserPermission.EVENT_GUEST_READ,
        UserPermission.EVENT_GUEST_CREATE,
        UserPermission.EVENT_GUEST_UPDATE,
        UserPermission.EVENT_GUEST_DELETE,

        UserPermission.BOOKING_CREATE,
        UserPermission.BOOKING_UPDATE,
        UserPermission.BOOKING_DELETE,
        UserPermission.BOOKING_DISABLE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_TARGETS_READ,
        UserPermission.BOOKING_TARGETS_CREATE,
        UserPermission.BOOKING_TARGETS_UPDATE,
        UserPermission.BOOKING_TARGETS_DELETE,
      ];
    case UserRole.COLLABORATOR:
      return [
        UserPermission.BLOG_READ,

        UserPermission.EVENT_CREATE,
        UserPermission.EVENT_UPDATE,

        UserPermission.EVENT_WIDGET_CREATE,
        UserPermission.EVENT_WIDGET_UPDATE,
        UserPermission.EVENT_WIDGET_DELETE,

        UserPermission.EVENT_SUB_WALLET,

        UserPermission.EVENT_SUB_READ,
        UserPermission.EVENT_SUB_CREATE,
        UserPermission.EVENT_SUB_UPDATE,
        UserPermission.EVENT_SUB_DELETE,

        UserPermission.EVENT_GUEST_LIST_READ,
        UserPermission.EVENT_GUEST_LIST_CREATE,
        UserPermission.EVENT_GUEST_LIST_UPDATE,
        UserPermission.EVENT_GUEST_LIST_DELETE,

        UserPermission.EVENT_TAG_READ,
        UserPermission.EVENT_TAG_CREATE,
        UserPermission.EVENT_TAG_UPDATE,
        UserPermission.EVENT_TAG_DELETE,

        UserPermission.EVENT_GUEST_READ,
        UserPermission.EVENT_GUEST_CREATE,
        UserPermission.EVENT_GUEST_UPDATE,
        UserPermission.EVENT_GUEST_DELETE,

        UserPermission.BOOKING_CREATE,
        UserPermission.BOOKING_UPDATE,
        UserPermission.BOOKING_DELETE,
        UserPermission.BOOKING_DISABLE,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.BOOKING_TEMPLATE_READ,
        UserPermission.BOOKING_TEMPLATE_UPDATE,

        UserPermission.BOOKING_TARGETS_READ,
        UserPermission.BOOKING_TARGETS_CREATE,
        UserPermission.BOOKING_TARGETS_UPDATE,
        UserPermission.BOOKING_TARGETS_DELETE,
      ];
    case UserRole.SALES_MANAGER:
      return [
        UserPermission.BLOG_READ,

        UserPermission.EVENT_CREATE,
        UserPermission.EVENT_UPDATE,

        UserPermission.EVENT_WIDGET_CREATE,
        UserPermission.EVENT_WIDGET_UPDATE,
        UserPermission.EVENT_WIDGET_DELETE,

        UserPermission.EVENT_SUB_READ,
        UserPermission.EVENT_SUB_CREATE,
        UserPermission.EVENT_SUB_UPDATE,
        UserPermission.EVENT_SUB_DELETE,

        UserPermission.EVENT_GUEST_LIST_READ,
        UserPermission.EVENT_GUEST_LIST_CREATE,
        UserPermission.EVENT_GUEST_LIST_UPDATE,
        UserPermission.EVENT_GUEST_LIST_DELETE,

        UserPermission.EVENT_TAG_READ,
        UserPermission.EVENT_TAG_CREATE,
        UserPermission.EVENT_TAG_UPDATE,
        UserPermission.EVENT_TAG_DELETE,

        UserPermission.EVENT_GUEST_READ,
        UserPermission.EVENT_GUEST_CREATE,
        UserPermission.EVENT_GUEST_UPDATE,
        UserPermission.EVENT_GUEST_DELETE,

        UserPermission.BOOKING_CREATE,
        UserPermission.BOOKING_UPDATE,
        UserPermission.BOOKING_DELETE,
        UserPermission.BOOKING_DISABLE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,
      ];
    case UserRole.SALES_ASSOCIATE:
      return [
        UserPermission.BLOG_READ,

        UserPermission.EVENT_SUB_READ,

        UserPermission.EVENT_GUEST_LIST_READ,
        UserPermission.EVENT_GUEST_LIST_CREATE,
        UserPermission.EVENT_GUEST_LIST_UPDATE,
        UserPermission.EVENT_GUEST_LIST_DELETE,

        UserPermission.EVENT_TAG_READ,

        UserPermission.EVENT_GUEST_READ,
        UserPermission.EVENT_GUEST_CREATE,
        UserPermission.EVENT_GUEST_UPDATE,
        UserPermission.EVENT_GUEST_DELETE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OWN_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.EVENT_TAG_READ,
      ];
    case UserRole.WELCOME_HOST:
      return [
        UserPermission.BLOG_READ,

        UserPermission.EVENT_SUB_READ,

        UserPermission.EVENT_GUEST_LIST_READ,

        UserPermission.EVENT_TAG_READ,

        UserPermission.EVENT_GUEST_READ,

        UserPermission.BOOKING_CREATE,
        UserPermission.BOOKING_UPDATE,
        UserPermission.BOOKING_DELETE,
        UserPermission.BOOKING_DISABLE,

        UserPermission.BOOKING_CALENDAR_MASTER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_READ,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_UPDATE,
        UserPermission.BOOKING_CALENDAR_OTHER_BOOKING_DELETE,
        UserPermission.BOOKING_CALENDAR_RESEND_CONFIRMATION_EMAIL,

        UserPermission.BOOKING_SETTINGS_READ,
        UserPermission.BOOKING_SETTINGS_UPDATE,

        UserPermission.EVENT_TAG_READ,
      ];
    default:
      return [];
  }
}
