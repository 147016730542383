import { useContext, useEffect } from 'react';
import { LayoutContext } from '../Layout/Layout';

/**
 * @param {boolean} onMount Visibility when the component mount (mandatory)
 * @param {boolean} onUnmount Visibility when the component unmount
 */
function useSetBreadcrumbVisible(onMount: boolean, onUnmount?: boolean) {
  const { setBreadcrumbVisible } = useContext(LayoutContext);

  useEffect(() => {
    setBreadcrumbVisible(onMount);

    return () => {
      if (onUnmount !== undefined) {
        setBreadcrumbVisible(onUnmount);
      }
    };
  }, []);
}

export default useSetBreadcrumbVisible;
