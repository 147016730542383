import useGetCurrentUser from './useGetCurrentUser';
import { UserPermission } from '../enums/UserPermission';
import getUserRolePermissions from './getUserRolePermissions';

type PermissionOrUndefined<T extends UserPermission | undefined> = T extends UserPermission
  ? boolean
  : (permission: UserPermission) => boolean;

/**
 * Check a permission for the current signed-in user
 */
function useCurrentUserCan<T extends UserPermission | undefined = undefined>(permission?: T): PermissionOrUndefined<T> {
  const currentUser = useGetCurrentUser();

  const rolePermissions = [...new Set(currentUser.roles.map((role) => getUserRolePermissions(role)).flat())];

  const userCan = (permission: UserPermission): boolean => {
    return rolePermissions.includes(permission);
  };

  if (permission) {
    return rolePermissions.includes(permission) as PermissionOrUndefined<T>;
  }

  return userCan as PermissionOrUndefined<T>;
}

export default useCurrentUserCan;
