import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import { getThemeAdaptedTokens } from '@ap/design-system/dist/helpers';
import { tokens } from '@ap/design-system-ts-tokens';

export const StyledStack = styled(Stack)<StackProps>(({ theme }) => {
  const mobileBreakpoint = theme.breakpoints.down('md');
  const { fd } = getThemeAdaptedTokens(theme);
  const {
    spacing: { gapforced },
  } = tokens.density.standard.ref;

  return {
    [mobileBreakpoint]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: fd.bg.container.secondary,
      padding: `${gapforced.xl}px ${gapforced.xxl}px ${gapforced.xl}px`,
      borderTop: `0.5px solid ${fd.stroke.neutral}`,
      justifyContent: 'space-between',
      alignItems: 'stretch',
      '& > button': {
        flex: 1,
      },
      zIndex: 100,
    },
  };
});
