/**
 * Performs a deep comparison between two objects to determine if they are equivalent.
 *
 * Shallow equality: get the list of properties (using Object.keys()) of both objects,
 * then check the properties’ values for equality.
 *
 * Deep equality: during the shallow check, if the compared properties are objects,
 * a recursive shallow equality check is performed on these nested objects.
 *
 * @param {object} object1
 * @param {object} object2
 * @return boolean Returns true if objects are equals, else false.
 */
function isObjectsDeepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];

    const areObjects = isObject(val1) && isObject(val2);

    // Recursive call starts to verify whether the nested objects are equal too
    if ((areObjects && !isObjectsDeepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

/**
 * Checks if the given parameter is an object.
 *
 * @param {any} object
 * @return boolean Returns true if it's an object
 */
function isObject(object: any) {
  return object != null && typeof object === 'object';
}

/**
 * Checks if the given object is empty (= has no properties = {})
 *
 * @param {any} object
 * @return boolean Returns true if it's empty
 */
function isObjectEmpty(object: any) {
  for (const prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) return false;
  }

  return true;
}

export { isObjectsDeepEqual, isObject, isObjectEmpty };
