import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { UserPermission } from '../../users/enums/UserPermission';
import bookingRoutes from '../../booking/router/routes';

const Overview = lazy(() => import('../pages/Overview'));
const Settings = lazy(() => import('../pages/Settings'));
const SettingsGeneral = lazy(() => import('../components/SettingsGeneral/SettingsGeneral'));
const SettingsSubEvents = lazy(() => import('../components/SettingsSubEvents/SettingsSubEvents'));
const SettingsGuestLists = lazy(() => import('../components/SettingsGuestLists/SettingsGuestLists'));
const SettingsTags = lazy(() => import('../components/SettingsTags/SettingsTags'));
const Guests = lazy(() => import('../../guests/pages/Guests'));

export type RouteObjectWithMeta = RouteObject & {
  meta?: {
    label?: string;
  };
};

const routesMasterEvent = (
  userCan: (permission: UserPermission) => boolean,
  isMobile: boolean,
): RouteObjectWithMeta[] => {
  return [
    {
      index: true,
      element: <Overview />,
      meta: {
        label: 'Overview',
      },
    },
    ...(userCan(UserPermission.EVENT_GUEST_READ)
      ? [
          {
            path: 'guests',
            element: <Guests />,
            meta: {
              label: 'Guests',
            },
          },
        ]
      : []),
    ...bookingRoutes(userCan),
    ...(isMobile
      ? [
          {
            path: 'settings',
            element: <SettingsGeneral />,
            meta: {
              label: 'Settings',
            },
          },
          ...(userCan(UserPermission.EVENT_SUB_READ)
            ? [
                {
                  path: 'settings/sub-events',
                  element: <SettingsSubEvents />,
                  meta: {
                    label: 'Sub-events',
                  },
                },
              ]
            : []),
          ...(userCan(UserPermission.EVENT_GUEST_LIST_READ)
            ? [
                {
                  path: 'settings/guest-lists',
                  element: <SettingsGuestLists />,
                  meta: {
                    label: 'Guest lists',
                  },
                },
              ]
            : []),
          ...(userCan(UserPermission.EVENT_TAG_READ)
            ? [
                {
                  path: 'settings/tags',
                  element: <SettingsTags />,
                  meta: {
                    label: 'Tags',
                  },
                },
              ]
            : []),
        ]
      : [
          {
            path: 'settings',
            element: <Settings />,
            meta: {
              label: 'Settings',
            },
          },
        ]),
  ];
};

export default routesMasterEvent;
