import { useContext, useState } from 'react';
import Stack from '@mui/material/Stack';
import { APButton, APButtonIcon, APIcon, APTooltip } from '@ap/design-system';
import EventReportsFiltersCountry from './EventReportsFiltersCountry';
import EventReportsFiltersStartEnd from './EventReportsFiltersStartEnd';
import { EventReportingContext, ReportsFilters } from '../../pages/EventReporting';
import useBreakpointDown from '../../../../template/hooks/useBreakpointDown';

function EventReportsFilters() {
  const { filters, setFilters } = useContext(EventReportingContext);

  const isMobile = useBreakpointDown('md');

  // @see comment on handleClearFilters()
  const [cleared, setCleared] = useState<number>(0);

  const hasFilters = filters.find((filter) => filter?.value && filter.value.length > 0) !== undefined;

  const handleChangeFilter = (filterId: string, value: any) => {
    setFilters(
      [...filters].map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            value,
          };
        }
        return filter;
      }),
    );
  };

  const handleClearFilters = () => {
    setFilters(
      [...filters].map((filter) => ({
        ...filter,
        value: [],
      })),
    );

    // Small hack to change the "key" attribute of the filters parent container
    // React will recreate the DOM and reset the state of the filters
    setCleared((prevState) => prevState + 1);
  };

  return (
    <Stack direction={isMobile ? 'column' : 'row'} spacing={4} width='100%'>
      <Stack key={`filters_${cleared}`} direction={isMobile ? 'column' : 'row'} spacing={4}>
        <EventReportsFiltersCountry
          onChange={(ids) => {
            handleChangeFilter(ReportsFilters.COUNTRIES, ids);
          }}
        />
        <EventReportsFiltersStartEnd
          onChange={(dateRange) => {
            handleChangeFilter(ReportsFilters.DATE_RANGE, dateRange);
          }}
        />
      </Stack>
      {hasFilters && !isMobile && (
        <APTooltip title='Clear all filters' arrow placement='right'>
          <APButtonIcon onClick={handleClearFilters} aria-label='clear'>
            <APIcon name='Cancel' />
          </APButtonIcon>
        </APTooltip>
      )}
      {hasFilters && isMobile && (
        <APButton size='small' variant='secondary' onClick={handleClearFilters} aria-label='clear'>
          Clear filters
        </APButton>
      )}
    </Stack>
  );
}

export default EventReportsFilters;
