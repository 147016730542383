import { MasterDataPeople, MasterDataPersonQueryInput } from '../models/MasterDataPerson';
import { useLazyQuery } from '@apollo/client';
import { GET_PEOPLE } from './query';

interface QueryData {
  masterDataPeople: MasterDataPeople;
}

interface QueryVars {
  query?: Pick<MasterDataPersonQueryInput, '_id_in'>;
}

/**
 * Fetch people matching the given externalUserIds
 */
function useFetchPeople() {
  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVars>(GET_PEOPLE, {
    notifyOnNetworkStatusChange: true,
  });

  const fetchPeople = async (externalUserIds?: string[]) => {
    const query = {
      query: {
        ...(externalUserIds && {
          _id_in: externalUserIds,
        }),
      } as MasterDataPersonQueryInput,
      sortBy: 'DISPLAYNAME_ASC',
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return response.data?.masterDataPeople ?? [];
  };

  return {
    fetchPeople,
    loading,
    error,
    people: data?.masterDataPeople ?? [],
  };
}

export default useFetchPeople;
