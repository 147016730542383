import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import { SelectProps as MuiSelectProps } from '@mui/material/Select/Select';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { APIcon, APIconNames } from '@ap/design-system';
import styles from './Select.module.scss';

export interface SelectItem {
  name: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  isListSubHeader?: boolean;
  muiIconName?: string;
}

interface SelectProps
  extends Omit<MuiSelectProps, 'id' | 'name' | 'value' | 'onChange' | 'error' | 'required' | 'size'> {}

interface Props {
  id: string;
  label: string;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: SelectInputProps['onChange'];
  onBlur?: SelectInputProps['onBlur'];
  value?: unknown;
  required?: boolean;
  disabledLabel?: boolean;
  items: SelectItem[];
  selectProps?: SelectProps;
  disabled?: boolean;
}

function Select(props: Props) {
  const {
    id,
    label,
    error = false,
    helperText,
    onChange,
    onBlur,
    value,
    required = false,
    disabledLabel = false,
    items = [],
    selectProps,
    disabled = false,
  } = props;

  return (
    <FormControl variant='outlined' component='fieldset' error={error} fullWidth>
      {!disabledLabel && (
        <InputLabel shrink htmlFor={id} className={styles.inputLabel}>
          {label}
          {required ? (
            <Box display='inline-block' sx={{ color: 'red', ml: 1 }}>
              *
            </Box>
          ) : (
            ''
          )}
        </InputLabel>
      )}
      <MuiSelect
        {...selectProps}
        size='small'
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        required={required}
        className={disabledLabel ? undefined : styles.select}
        disabled={disabled}
      >
        {items.map((item, index) => {
          if (item.isListSubHeader) {
            return <ListSubheader key={index}>{item.name}</ListSubheader>;
          }

          return (
            <MenuItem key={index} value={item.value}>
              {item?.muiIconName && <APIcon name={item.muiIconName as APIconNames} sx={{ mr: 2 }} />} {item.name}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default Select;
