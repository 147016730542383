import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { APDialog } from '@ap/design-system';
import { APDialogProps } from '@ap/design-system/dist/components/APDialog/types';
import styles from './Dialog.module.scss';
import { isObjectEmpty } from '../../../helper/object';

interface Props {
  id: string;
  className?: string;
  children: string | React.ReactElement | React.ReactElement[];
  open?: boolean;
  onClose?: (reload: boolean, data?: any) => void;
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?: APDialogProps['maxWidth'];
  fullScreen?: boolean;
  fullScreenWithMargin?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackDropClick?: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: string | React.ReactNode;
  onClose?: (reload: boolean, data?: any) => void;
  actions?: React.ReactNode;
  actionsOnTop?: boolean;
  titleStyle?: SxProps<Theme>;
}

function Dialog(props: Props) {
  const {
    id,
    className,
    open = true,
    fullScreen = false,
    fullScreenWithMargin = false,
    onClose,
    maxWidth = 'lg',
    title,
    children,
    actions,
    disableEscapeKeyDown,
    disableBackDropClick,
  } = props;

  const handleClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && isObjectEmpty(event) && onClose) {
      // Handle click on the close button
      onClose(false);
    }
    if (reason === 'backdropClick' && disableBackDropClick) {
      // Prevent closing the dialog on backdrop click
      return;
    }
    if (reason === 'escapeKeyDown' && disableEscapeKeyDown) {
      // Prevent closing the dialog on escape key press
      return;
    }
  };

  return (
    <APDialog
      id={`${id}-root`}
      open={open}
      title={title}
      onClose={handleClose}
      maxWidth={fullScreenWithMargin ? false : maxWidth}
      fullScreen={fullScreen}
      fullWidth={true}
      aria-labelledby={id}
      scroll='paper'
      className={className}
      classes={{
        ...(fullScreenWithMargin && {
          paperFullWidth: styles.fullscreenWithMarginPaper,
        }),
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      actions={actions}
    >
      {children}
    </APDialog>
  );
}

export default Dialog;
