import { StoreObject, useMutation } from '@apollo/client';
import { DELETE_NOTIFICATION } from './query';
import useLogEvent, { LogLevel } from '../../../helper/useLogEvent';
import Notification from '../models/Notification';

interface MutationData {
  deleteOneNotification: Notification;
}

interface MutationVariables {
  query: {
    _id: string;
  };
}

/**
 * Delete a notification
 */
function useDeleteNotification() {
  const [execute, { data, error, loading, client }] = useMutation<MutationData, MutationVariables>(DELETE_NOTIFICATION);
  const { logEvent } = useLogEvent();

  const deleteNotification = async (notification: Notification) => {
    const response = await execute({
      variables: {
        query: {
          _id: notification._id,
        },
      },
    });

    // Manually update the Apollo cache
    client.cache.modify({
      fields: {
        notifications: (previous) => {
          const storedObject = response.data?.deleteOneNotification as unknown as StoreObject;
          return [...previous].filter((item) => item.__ref !== storedObject._id);
        },
      },
    });

    logEvent(LogLevel.Info, 'Delete notification', {
      id: notification._id,
    });

    return response.data?.deleteOneNotification;
  };

  return { deleteNotification, loading, error, id: data?.deleteOneNotification };
}

export default useDeleteNotification;
