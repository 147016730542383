import { lazy } from 'react';
import { RouteObject } from 'react-router';
import routesChildren from './routesChildren';
import { UserPermission } from '../../users/enums/UserPermission';
import { URI_EVENT, URI_MASTER_EVENTS } from './uri';

const MasterEvents = lazy(() => import('../pages/MasterEvents'));
const MasterEvent = lazy(() => import('../pages/MasterEvent'));

const routes = (userCan: (permission: UserPermission) => boolean, isMobile: boolean): RouteObject[] => {
  return [
    {
      path: URI_MASTER_EVENTS,
      element: <MasterEvents />,
    },
    {
      path: `${URI_EVENT}/:masterEventId`,
      element: <MasterEvent />,
      children: routesChildren(userCan, isMobile).map(({ meta, ...item }) => item),
    },
  ];
};

export default routes;
