import { enUS } from 'date-fns/locale';

/**
 * Override the default options for the enUs date-fns locale
 */
export default function getCustomEnUsLocale(): Locale {
  const locale = enUS;

  if (locale.options) {
    // Week starts on Monday
    locale.options.weekStartsOn = 1;
  }

  return locale;
}
