import { lazy } from 'react';
import { RouteObjectWithMeta } from '../../masterEvents/router/routesChildren';
import { URI_BOOKING, URI_BOOKING_SETTINGS, URI_BOOKING_TARGETS, URI_BOOKING_TEMPLATES, URI_BOOKINGS } from './uri';
import { UserPermission } from '../../users/enums/UserPermission';

const Bookings = lazy(() => import('../pages/Bookings'));
const Booking = lazy(() => import('../pages/Booking'));
const BookingCalendar = lazy(() => import('../pages/BookingCalendar'));
const BookingTargets = lazy(() => import('../pages/BookingTargets'));
const BookingTemplates = lazy(() => import('../pages/BookingTemplates'));
const BookingSettings = lazy(() => import('../pages/BookingSettings'));

const routesBookings = (userCan: (permission: UserPermission) => boolean): RouteObjectWithMeta[] => {
  return [
    {
      path: URI_BOOKINGS,
      element: <Bookings />,
      meta: {
        label: 'Bookings',
      },
    },
    {
      path: `${URI_BOOKING}/:bookingId`,
      element: <Booking />,
      children: [
        {
          index: true,
          element: <BookingCalendar />,
        },
        {
          path: URI_BOOKING_TARGETS,
          element: <BookingTargets />,
        },
        {
          path: URI_BOOKING_TEMPLATES,
          element: <BookingTemplates />,
        },
        {
          path: URI_BOOKING_SETTINGS,
          element: <BookingSettings />,
        },
      ],
    },
  ];
};

export default routesBookings;
