import { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_SUBEVENTS } from './query';
import { MasterEventContext } from '../../pages/MasterEvent';
import SubEvent, { SubEventQueryInput } from '../../models/SubEvent';
import SortBy from '../../../../models/SortBy';

interface QueryData {
  subEvents: SubEvent[];
}

interface QueryVars {
  sortBy: string;
  query: Pick<SubEventQueryInput, '_partition' | '_partition_in'>;
}

/**
 * Fetch sub events of the current selected master event
 */
function useFetchSubEvents() {
  const { masterEvent } = useContext(MasterEventContext);

  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVars>(GET_SUBEVENTS, {
    notifyOnNetworkStatusChange: true,
  });

  const fetchSubEvents = async (sortBy?: SortBy, _partition_in?: string[]) => {
    const sortField = sortBy?.field.split('.')[0].toUpperCase() || 'NAME';
    const sortDirection = sortBy?.sort.toUpperCase() || 'ASC';
    const sortProperty = `${sortField}_${sortDirection}`;

    const query: QueryVars = {
      query: {
        ...(_partition_in ? { _partition_in: _partition_in } : { _partition: masterEvent._id }),
      },
      sortBy: sortProperty,
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return response.data?.subEvents || [];
  };

  return {
    fetchSubEvents,
    loading,
    error,
    subEvents: data?.subEvents || [],
  };
}

export default useFetchSubEvents;
