import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { URI_DEVICES } from './uri';
import { UserPermission } from '../../users/enums/UserPermission';

const Devices = lazy(() => import('../pages/AppDevices'));

const routes = (userCan: (permission: UserPermission) => boolean): RouteObject[] => {
  if (!userCan(UserPermission.DEVICES_MANAGE)) {
    return [];
  }

  return [
    {
      path: URI_DEVICES,
      element: <Devices />,
    },
  ];
};

export default routes;
