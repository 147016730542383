import { useLazyQuery } from '@apollo/client';
import { GET_MASTER_DATA_COUNTRIES } from './query';
import MasterDataCountry from '../models/MasterDataCountry';
import { Language } from '../../languages/enums/language.enum';

interface QueryData {
  masterDataCountries: MasterDataCountry[];
}

interface QueryVariables {
  query: {
    _partition: string;
  };
}

/**
 * Sets the country name to the country object.
 * Takes arguments twice to allow usage as a mapping function.
 */
export const setCountryName =
  (language: Language) =>
  (country: MasterDataCountry): MasterDataCountry => {
    return {
      ...country,
      name: country?.translations.find((t) => t._id == language)?.name ?? '',
    };
  };

function useFetchCountries() {
  const [execute, { loading, error, data }] = useLazyQuery<QueryData, QueryVariables>(GET_MASTER_DATA_COUNTRIES, {
    notifyOnNetworkStatusChange: true,
  });

  const setLocaleCountryName = setCountryName(Language.English);

  const fetchCountries = async () => {
    const response = await execute({
      variables: {
        query: {
          _partition: 'MASTER',
        },
      },
    });

    return response.data?.masterDataCountries.map(setLocaleCountryName) || [];
  };

  return { fetchCountries, loading, error, countries: data?.masterDataCountries.map(setLocaleCountryName) || [] };
}

export default useFetchCountries;
