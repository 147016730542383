import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { tokens } from '@ap/design-system-ts-tokens';
import { LayoutContext } from '../Layout/Layout';

interface Props {
  children: JSX.Element | React.ReactNode;
  pageTitle?: string;
}

function PageContainer(props: Props) {
  const { children, pageTitle } = props;

  const { containerSx } = useContext(LayoutContext);

  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.down('md');

  const {
    spacing: { gapforced },
    radius,
  } = tokens.density.standard.ref;

  return (
    <Fragment>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          // padding: `${gapforced.x5l}px ${gapforced.x6l}px 0 ${gapforced.x6l}px`,
          padding: theme.spacing(7),
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderRadius: radius.none,
          height: '100%',
          width: '100%',
          gap: theme.spacing(7),

          [mobileBreakpoint]: {
            padding: `${gapforced.x5l}px ${gapforced.xl}px ${gapforced.x5l}px ${gapforced.xl}px`,
          },

          ...containerSx,
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}

export default PageContainer;
