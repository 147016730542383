import { useContext, useState } from 'react';
import Stack from '@mui/material/Stack';
import { AppContext } from '../../../bootstrap/App';
import { UserRegionalFormat, UserRegionalFormatList } from '../../../features/users/enums/UserRegionalFormat';
import Select from '../Select/Select';
import DateFormat from '../DateFormat/DateFormat';
import Typography from '@mui/material/Typography';

interface Props {
  setData: (data: Array<{ name: string; value: unknown }>) => void;
}

function WelcomeWizardRegionalFormat(props: Props) {
  const { setData } = props;

  const [value, setValue] = useState(UserRegionalFormat.ENGLISH_US);

  const { setRegionalFormat } = useContext(AppContext);

  const handleChange = async (newValue: UserRegionalFormat) => {
    setValue(newValue);

    await setData([{ name: 'regionalFormat', value: newValue }]);
    setRegionalFormat(newValue);
  };

  return (
    <Stack spacing={3}>
      <Select
        id='regionalFormat'
        label='Regional format'
        items={UserRegionalFormatList}
        value={value}
        onChange={(event) => handleChange(event.target.value as UserRegionalFormat)}
        required={true}
      />
      <Typography variant='footnote' component='div'>
        Date and time will be displayed as follow: <DateFormat date={new Date()} showTime />
      </Typography>
    </Stack>
  );
}

export default WelcomeWizardRegionalFormat;
