export interface Settings {
  plural?: boolean;
  capitalize?: boolean;
}

function useGetGuestLabel() {
  const getGuestLabel = (settings?: Settings) => {
    const plural = settings?.plural ?? false;
    const capitalize = settings?.capitalize ?? false;
    const guest = capitalize ? 'Guest' : 'guest';

    return `${guest}${plural ? 's' : ''}`;
  };

  return { getGuestLabel };
}

export default useGetGuestLabel;
