import { useEffect, useMemo, useState } from 'react';
import { APInputSelectField } from '@ap/design-system';
import {
  APInputSelectFieldSelectNormalOptionProps,
  APInputSelectFieldSelectOptionProps,
} from '@ap/design-system/dist/components/APInputSelectField/types';

export interface Item {
  _id: string;
  label: string;
  info?: string;
}

interface Props {
  id: string;
  label: string;
  items: Item[];
  initialValue?: string[];
  value?: string[];
  onChange: (ids: string[]) => void;
  enableSelectAll?: boolean;
  minWidth?: number;
  maxWidth?: number;
  multiple?: boolean;
}

function GuestsFilter(props: Props) {
  const {
    id,
    label,
    items,
    initialValue,
    value,
    onChange,
    enableSelectAll = true,
    minWidth = 170,
    maxWidth = 170,
    multiple = true,
  } = props;

  const [loaded, setLoaded] = useState<boolean>(false);
  const [selection, setSelection] = useState<string[]>(initialValue ?? []);

  useEffect(() => {
    if (loaded) {
      // Only trigger onChange when the component has been loaded (to avoid side effects with parent components)
      onChange(selection);
    } else {
      setLoaded(true);
    }
  }, [selection]);

  useEffect(() => {
    if (value && selection !== value) setSelection(value);
  }, [value]);

  const handleChange = (value: string | string[]) => {
    if (!multiple) {
      const id = value as string;
      setSelection(selection.indexOf(id) > -1 ? [] : [id]);
      return;
    }

    const ids = value as string[];
    if (selection.indexOf('all') > -1) {
      // "All" has previously been checked
      if (ids.indexOf('all') === -1) {
        // Uncheck "All"
        setSelection([]);
      } else {
        setSelection(ids.filter((id) => id !== 'all'));
      }
    } else {
      if (ids.indexOf('all') > -1) {
        setSelection([...items.map((item) => item._id), 'all']);
      } else {
        setSelection(ids);
      }
    }
  };

  const options = useMemo(() => {
    let options: APInputSelectFieldSelectOptionProps[] = [];

    if (items.length > 0) {
      if (enableSelectAll && multiple) {
        options = [
          ...options,
          {
            value: 'all',
            label: 'All',
          },
          {
            divider: true,
          },
        ];
      }

      options = [
        ...options,
        ...items.map((item) => ({
          value: item._id,
          label: item.label,
        })),
      ];
    } else {
      options = [
        {
          value: 'no-options',
          label: 'No options',
        },
      ];
    }

    return options;
  }, [items]);

  return (
    <APInputSelectField
      id={id}
      label=''
      hiddenLabel
      placeholder={label}
      options={options}
      value={selection}
      onChange={(event) => handleChange(event.target.value)}
      multiple={multiple}
      select
      customWidth='150px'
      renderValueFilter={(values) => (values as APInputSelectFieldSelectNormalOptionProps).value !== 'all'}
    />
  );
}

export default GuestsFilter;
