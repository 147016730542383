import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { OperationTypeNode } from 'graphql';

export function cleanObjectRecursively<T>(obj: T): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function replaceNullAndTrimString(value: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let newValue: any = value;

    if (value === null) {
      // Replace "null" with "undefined"
      newValue = undefined;
    } else if (typeof newValue === 'string') {
      // Trim string
      newValue = newValue.trim();
    }

    return newValue;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => cleanObjectRecursively(v)) as unknown as T;
  } else if (typeof obj !== 'object') {
    return replaceNullAndTrimString(obj);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(obj as any).forEach((k) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = (obj as any)[k];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let newValue: any = replaceNullAndTrimString(value);

    // Apply function recursively
    if (Array.isArray(newValue)) {
      newValue = newValue.map((v) => cleanObjectRecursively(v));
    } else if (typeof newValue === 'object' && newValue.__proto__.constructor === Object) {
      newValue = cleanObjectRecursively(newValue);
    }

    // Remove __typename
    if ((k as keyof T) !== '__typename') {
      newObj[k as keyof T] = newValue;
    }
  });

  return newObj;
}

/**
 * Apollo Link request handler that, only on mutation:
 * - remove every nested __typename key it finds in the variables
 * - trim all strings
 * - replace "null" value with "undefined"
 *
 * @param {Operation} operation The GraphQL operation that's being passed through the link
 * @param {NextLink} forward A function for executing the next link in the chain
 */
const cleanDataForMutation = (operation: Operation, forward: NextLink) => {
  const definition = operation?.query?.definitions.filter((def) => def.kind === 'OperationDefinition')?.[0];
  // const mutation: OperationTypeNode = 'mutation';

  if (definition?.kind == 'OperationDefinition' && definition?.operation === OperationTypeNode.MUTATION) {
    if (!operation.variables || typeof operation.variables !== 'object') {
      return forward(operation);
    }

    operation.variables = cleanObjectRecursively(operation.variables);

    return forward(operation);
  }

  return forward(operation);
};

const link = new ApolloLink(cleanDataForMutation);

export default link;
