import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import { APIcon } from '@ap/design-system';

function BackToTop() {
  const trigger = useScrollTrigger({
    target: window || undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        title='Back to top'
      >
        <Fab color='primary' size='small' aria-label='Back to top'>
          <APIcon name='KeyboardArrowUp' />
        </Fab>
      </Box>
    </Zoom>
  );
}

export default BackToTop;
