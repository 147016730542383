import { ApolloClient, ApolloLink, defaultDataIdFromObject, from, InMemoryCache } from '@apollo/client';
import cleanMutationLink from './getApolloCleanMutationLink';
import httpLink from './getApolloHttpLink';

const getApolloClient = async (tokenLink: ApolloLink) => {
  return new ApolloClient({
    link: from([cleanMutationLink, tokenLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        MasterDataCountryTranslation: { keyFields: ['_id', 'name'] },
      },
      dataIdFromObject: (responseObject) => {
        return responseObject?._id && typeof responseObject._id === 'string'
          ? (responseObject._id as string)
          : defaultDataIdFromObject(responseObject);
      },
    }),
  });
};

export default getApolloClient;
