import { useContext } from 'react';
import { MasterEventContext } from '../features/masterEvents/pages/MasterEvent';

const disableLogs = import.meta.env.VITE_DISABLE_LOGS === 'true';

export enum LogLevel {
  Info = 'info',
  Notice = 'notice',
  Warning = 'warning',
  Error = 'error',
  Critical = 'crit',
  Alert = 'alert',
  Emergency = 'emerg',
}

interface Data {
  [key: string]: any;
}

function useLogEvent() {
  const { masterEvent } = useContext(MasterEventContext);

  /**
   * Save Event log
   *
   * @param {LogLevel} level Log level
   * @param {string} message
   * @param {any} data additional object data
   */
  const logEvent = async (level: LogLevel, message: string, data?: Data) => {
    if (disableLogs) {
      return;
    }

    const masterEventId = masterEvent._id ?? undefined;

    try {
      // TODO : Split - reimplementing logging
      //      await azureClient.post('logs', { level, message, masterEventId, ...data });
    } catch (error) {
      console.error('Cannot send log event', error);
    }
  };

  return { logEvent };
}

export default useLogEvent;
