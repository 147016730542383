import * as Realm from 'realm-web';

const app = new Realm.App({
  id: import.meta.env.VITE_APP_ID as string,
});

const getRealmUser = async (accessToken: string) => {
  const credentials = Realm.Credentials.jwt(accessToken);

  // Guarantee that there's a logged in user with a valid access token
  if (!app.currentUser) {
    // Authenticate the user
    await app.logIn(credentials);
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await app.currentUser.refreshCustomData();
  }

  return { ...app.currentUser?.customData, accessToken: app.currentUser?.accessToken };
};

const getRealmObject = () => app.currentUser;

export default getRealmUser;
export { getRealmObject };
