import { useLazyQuery } from '@apollo/client';
import SortBy from '../../../models/SortBy';
import { GET_NOTIFICATIONS } from './query';
import Notification, { NotificationQueryInput } from '../models/Notification';

interface QueryData {
  notifications: Notification[];
}

interface QueryVars {
  sortBy: string;
  query: Pick<NotificationQueryInput, '_partition' | 'group_in'>;
}

/**
 * Fetch all the notifications
 */
function useFetchNotifications() {
  const [execute, { loading, error, data, refetch, called }] = useLazyQuery<QueryData, QueryVars>(GET_NOTIFICATIONS, {
    notifyOnNetworkStatusChange: true,
  });

  const fetchNotifications = async (groups: string[], sortBy?: SortBy) => {
    const sortField = sortBy?.field.split('.')[0].toUpperCase() || '_ID';
    const sortDirection = sortBy?.sort.toUpperCase() || 'ASC';
    const sortProperty = `${sortField}_${sortDirection}`;

    const query: QueryVars = {
      query: {
        _partition: 'MASTER',
        group_in: groups,
      },
      sortBy: sortProperty,
    };

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return response.data?.notifications || [];
  };

  return { fetchNotifications, loading, error, notifications: data?.notifications || [] };
}

export default useFetchNotifications;
